import { isEmpty } from 'lodash';
import type { ReactElement } from 'react';

import { BaseBlock } from '@/components/base-block';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { Tag } from '@/components/tag';
import type { PromoTileBlockType } from '@/lib/types';
import { Tile } from '@/components/promo-tile-block/tile';

const PromoTileBlock = ({
  title,
  link,
  linkText,
  items,
  theme = 'Light',
  anchor,
}: PromoTileBlockType): ReactElement => {
  const tile = <Tile title={title} link={link} linkText={linkText} />;

  if (isEmpty(items)) {
    return (
      <BaseBlock className="promotion-tile col-12" width="Narrow" theme={theme}>
        {tile}
      </BaseBlock>
    );
  }

  return (
    <BaseBlock className="snippet" width="Normal" theme={theme} anchor={anchor}>
      <div className="snippet-inner">
        <div className="snippet-container">
          <div className="snippet">
            <div className="row">
              <div className="component link-list-with-images list col-12 col-sm-12 col-md-6">
                <div className="component-content">
                  <ul>
                    {items.map((item) => {
                      const tag = item.type === 'article' || item.type === 'journey' ? item.tag : null;
                      return (
                        <li key={item.id}>
                          <Link
                            href={getModelURL(item)}
                            analytics={{
                              context: 'Promo Content',
                              text: item.title,
                            }}
                          >
                            <div className="item-wrapper">
                              <div className="image-wrapper">
                                {item.heroImage && (
                                  <div className="field-linkimage">
                                    <Image {...item.heroImage} isBackground />
                                  </div>
                                )}
                              </div>
                              <div className="text-wrapper">
                                <div className="field-teasertext">{item.title}</div>
                                <Tag tag={tag} />
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="component promotion-tile feature-item-image-left col-12 col-sm-12 col-md-6 order-first order-sm-first order-md-last">
                <div className="component-content">{tile}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseBlock>
  );
};

export { PromoTileBlock };
