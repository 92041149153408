import { ReactElement } from 'react';

import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Link } from '@/components/link';
import type { PromoTileBlockType } from '@/lib/types';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

type TileProps = Pick<PromoTileBlockType, 'title' | 'link' | 'linkText'>;

const Tile = ({ title, link, linkText }: TileProps): ReactElement => {
  const router = useRouter();

  return (
    <div
      className={clsx(
        'promotion-wrapper',
        router.route === '/' && HUNDRED_YEAR_TAKEOVER ? 'hundred-year' : 'bg-active-blue'
      )}
    >
      {title && (
        <div className="promotion-text">
          <h2 className="text-white mb-3">{title}</h2>
        </div>
      )}
      {link && (
        <Link
          className="btn btn-sm btn-white"
          href={getModelURL(link)}
          analytics={{
            context: 'CTA - Promo Tile',
            linkText,
          }}
        >
          {linkText}
        </Link>
      )}
    </div>
  );
};

export { Tile };
