import { isEmpty, kebabCase } from 'lodash';
import { ReactElement } from 'react';
import clsx from 'clsx';

import { Tag as TagType } from '@/lib/types';
import { useRouter } from 'next/router';
import { HUNDRED_YEAR_TAKEOVER } from '@/lib/constants';

const Tag = ({ tag }: { tag: TagType | null }): ReactElement | null => {
  const router = useRouter();
  if (!tag || isEmpty(tag.title) || isEmpty(tag.colour)) {
    return null;
  }

  const tagColourClassName =
    router.route === '/' && HUNDRED_YEAR_TAKEOVER ? 'hundred-year-theme' : `text-${kebabCase(tag?.colour)}`;

  return <div className={clsx('tag', tagColourClassName)}>{tag.title}</div>;
};

export { Tag };
